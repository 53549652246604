import { axiosObject } from "../setup";

function getDriverDisplayName(driver) {
  let display_string = driver;

  switch (driver) {
    case "email_ses":
      display_string = "ses";
      break;

    case "email_mailgun":
      display_string = "mailgun";
      break;

    case "sms_digimiles":
      display_string = "digmiles";
      break;

    case "sms_smsgatewayhub":
      display_string = "smsgatewayhub";
      break;

    case "sms_sns":
      display_string = "sns";
      break;

    default:
      display_string = display_string;
      break;
  }

  return display_string;
}

function userCanCreateProvider(payload) {
  return new Promise((resolve, reject) => {
    let uri = "/neptune/providers";

    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function userCanDeleteProvider(uuid) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/providers/${uuid}`;

    axiosObject
      .delete(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function userCanUpdateProvider(uuid, payload) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/providers/${uuid}`;

    axiosObject
      .put(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function userCanViewProvider(uuid) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/providers/${uuid}`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function userCanViewAllProviders() {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/providers`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

export {
  userCanCreateProvider,
  userCanDeleteProvider,
  userCanUpdateProvider,
  userCanViewProvider,
  userCanViewAllProviders,
  getDriverDisplayName,
};
